/* 
  ==================
  LAYOUT MODIFICATION 
  =================
*/

.block {
  display: block;
}

.inline {
  display: inline;
}

.none {
  display: none;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: end;
}

.items-start {
  align-items: start;
}

.sized-50 {
  height: 50px !important;
}

.centered {
  place-items: center;
}

/* 
  ==================
  TEXT MODIFICATION 
  =================
*/
.underline {
  text-decoration: underline;
}