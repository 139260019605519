.rdw-option-wrapper {
    height: 28px;
    width: 28px;
    border-radius: 6px;
    padding: 4px;
    border: 0;
    box-shadow: 0;
}

.rdw-option-wrapper:hover {
    box-shadow: none;
}

.rdw-option-wrapper:active {
    border: 1px solid #e7e7e7;
    box-shadow: none;
}

.rdw-option-active {
    border: 1px solid #e7e7e7;
    box-shadow: none;
}

.rdw-editor-wrapper {
    width: 100%;
}

.rdw-editor-toolbar {
    border: 1px solid #e7e7e7;
    border-radius: 16px 16px 0 0;
    border-bottom: 0;
    margin-bottom: 0;
}

.rdw-editor-main {
    border-radius: 0 0 16px 16px !important;
    resize: vertical;
    width: 100%;
    border: 1px solid #e7e7e7;
    min-height: 200px;
    padding: 10px;
}

.rdw-colorpicker-modal {
    width: 205px !important;
    height: 220px !important;
}

.rdw-colorpicker-option {
    border: 0;
    box-shadow: none;
}

.rdw-colorpicker-option:hover {
    border: 0;
    box-shadow: none;
}

.rdw-colorpicker-option:active {
    border: 0;
    box-shadow: none;
}

.rdw-dropdown-wrapper {
    border-radius: 6px;
}

.rdw-dropdown-wrapper:focus {
    box-shadow: none;
}

.rdw-dropdown-wrapper:hover {
    border: 1px solid #e7e7e7;
    box-shadow: none;
}

.rdw-dropdown-wrapper:active {
    border: 1px solid #e7e7e7;
    box-shadow: none;
}

.rdw-dropdown-optionwrapper:hover {
    border: 1px solid #e7e7e7;
    box-shadow: none;
}

.full-width {
    width: 100%;
}