.react-datepicker {
  display: flex;
}

.calendar-btn {
  width: 50px;
  margin: 5px;
  border-radius: 0.25rem !important;
}

.nav-upcoming-event {
  color: var(--primary);
  padding: 0.5rem;
}

.upcoming-event-date {
  color: #6c757d;
  font-size: 10px;
}

.userbox-icon-wrapper {
  cursor: pointer;
}

.userbox-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.profile-btn-wrapper {
  padding-left: 10px;
  border-left: 1px solid grey;
  cursor: pointer;
}

.profile-btn-popover-wrapper {
  font-size: 12px;
  cursor: pointer;
}

.profile-btn-popover-wrapper a {
  display: block;
  padding: 5px;
}

.nav-upcoming-event strong {
  text-transform: capitalize;
  font-size: 12px;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-size: 12px;
}

.menu-item {
  margin: 0 auto;
}

.help-link,
.help-link:hover,
.help-link:focus,
.help-link:visited {
  color: #000000d9;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border-radius: 12px;
  font-weight: 400;
  font-size: 15px;
  z-index: 999 !important;
  background-color: #fff !important;
  padding: 10px;
  box-shadow: 0 0px 8px 0 rgb(5 34 97 / 10%);
  border: none;
}

.react-autosuggest__suggestion {
  border: 1px solid transparent;
  border-radius: 15px;
  transition: 0.2s ease all;
}

.react-autosuggest__suggestion:hover {
  border: 1px solid #2d51e5;
  background-color: #2d51e514 !important;
  color: #2d51e5;
}

.page-title {
  font-weight: 800;
  font-size: 1em;
}

.ant-tag {
  margin-right: 0px;
}

.nextui-collapse-title {
  font-size: 1.4em;
}
