.notes-wrapper .textarea-input > textarea {
  height: 100% !important;
}

.notes-wrapper .comment-list .ant-comment-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notes-wrapper .comment-list {
  max-height: 200px !important;
  /* overflow: auto !important; */
}

.notes-wrapper .input-wrapper {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.notes-wrapper button {
  margin-top: 15px;
}

.comment > * {
  width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
}