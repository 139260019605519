.nav-view-choice {
  padding: 0 15px;
  margin-left: auto;
  margin-right: auto;
}

.detail-info-wrapper p {
  display: flex;
  justify-content: space-between;
}

.candidate-search-input {
  border-radius: 0;
}

.candidate-search-input:focus {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: none;
}

.modal-wrapper .emailSuccess {
  border: #30a66a solid 1px;
}

.modal-wrapper .emailFailed {
  border: #e91e62 solid 1px;
}

.modal-wrapper .form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.modal-wrapper .col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.modal-wrapper .col-form-label {
  padding-bottom: 0;
  margin-left: 3px;
  padding-left: 0;
}

.add-file-section {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: white;
}

.pipelines-settings {
  position: fixed;
  align-items: center;
  width: calc(100vw - 120px);
}

.back-btn-with-border {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
  background-color: #f3f3f4;
  box-shadow: 0 0.15rem 0.4rem rgba(0, 0, 0, 0.15);
}

.tag-contract-type {
  font-size: 13px;
  font-weight: 800;
  border-radius: 5px;
  padding: 0.01em 0.5em;
}

.crm-wrapper {
  display: inline-flex;
  flex-direction: column;
  padding-top: 80px;
}

.suggestions-wrapper {
  flex-direction: column;
  padding-top: 80px;
}

.crm-tabs .ant-tabs-nav {
  width: 100%;
  position: fixed !important;
  top: 120px !important;
  z-index: 133;
}

.without-padding {
  padding: 0px 1.1em;
  margin: 0px;
}

.ant-tabs-content-holder::after {
  content: '';
  display: block;
  background-color: black;
}

.btn-hired {
  display: flex;
  gap: 5px;
  align-items: center;
}
