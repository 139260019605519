.email-modal {
  max-width: 760px;
  min-width: 760px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.mail-editor {
  min-height: 180px !important;
  max-height: 300px;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.dropzone-mail-attachments {
  height: 150px;
  width: 100%;
  cursor: pointer;
  margin-right: 10px;
}

.email-dropzone > .dropzone-content > p {
  height: 40px;
  line-height: 4;
}

.div-mail-attachments {
  display: flex;
}

.mail-input {
  height: 200px !important;
  font-size: 13px;
}

.bg-mail {
  background-color: #d44638;
  color: white;
}

.rdw-editor-main {
  resize: vertical;
  width: 100%;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  min-height: 200px;
  padding: 10px;
}
