@import './_globales.scss';
@import './variables';

:root {
  --height-navbar: 56.05px;
  --height-layout-tab-header: 275px;
  --blank-space-layout-tab-header: 194px;

  // To make scss variables usable in styled components
  --primary: #0070f3;
  --secondary: #d6e3fd;
  --success: #20bf6b;
  --info: #00bcd4;
  --warning: #ffc107;
  --danger: #ff7466;
  --light: #f8f9fa;
  --dark: black;
  --gray: #adb5bd;
  --orange: #ff5722;
  --purple: #673ab7c7;
  --green: #6bcb3a;
  --pink: #eb5aac;
}

body {
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular',
    sans-serif;
  line-height: 1.6;
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: black !important;
}

img {
  align-self: center;
}

.ant-modal-mask,
.modal-backdrop,
.resume-modal-overlay,
.ant-drawer-mask {
  background-color: #172b4dbd !important;
}

.react-responsive-modal-modal {
  max-width: 100% !important;
  border-radius: 10px;
}

.resume-modal {
  width: 100% !important;
  margin: 1.2rem auto !important;
  top: 2rem !important;
  min-height: 140vh;
  border-radius: 15px;
}

.ant-drawer-content {
  border-radius: 15px !important;
}

.right .ant-drawer-content {
  border-radius: 20px 0px 0px 20px !important;
}

.ant-drawer > * {
  transition: transform 0.05s cubic-bezier(0.3, 0.3, 0.1, 1),
    box-shadow 0.05s cubic-bezier(0.3, 0.3, 0.1, 1),
    -webkit-transform 0.05s cubic-bezier(0.3, 0.3, 0.1, 1),
    -webkit-box-shadow 0.05s cubic-bezier(0.3, 0.3, 0.1, 1) !important;
}

.ant-drawer-open {
  display: flex;
  justify-content: center;
}

.ant-drawer-content-wrapper {
  display: flex;
  justify-content: center;
}

.ant-drawer-right {
  position: fixed;
  top: 54px;
  z-index: 1000;
}

.right .ant-drawer-content {
  border-radius: 0 !important;
}

.resume-slider-drawer .ant-drawer-content {
  width: 93%;
}

.form-control {
  font-size: 14px;
}

.table-action-elem {
  margin: 3px;
}

.pagination-parent {
  display: flex;
}

.pagination {
  margin: auto !important;
}

.clickable-row {
  cursor: pointer;
}

.fixed-footer .app-footer {
  z-index: 1001;
}

.messaging-options {
  width: 500px;
  margin: auto;
}

.textarea-input {
  height: 100px;
}

textarea {
  overflow: auto !important;
}

textarea:disabled {
  background-color: #e9ecef !important;
}

.not-seen {
  font-weight: bold;
}

.btn-search {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;
  font-size: 30px;
  color: var(--primary);
  background: transparent;
  padding: 2px;
  border-color: transparent;
}

.btn-search:hover,
.btn-search.focus,
.btn-search:focus {
  background: transparent !important;
  border-color: transparent !important;
  color: var(--primary) !important;
}

.btn-outline-messenger {
  color: #0084ff;
  border-color: #0084ff;
}

.btn-outline-messenger:hover {
  color: #fff;
  background-color: #0084ff;
}

.btn-outline-messenger:focus,
.btn-outline-messenger.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-messenger.disabled,
.btn-outline-messenger:disabled {
  color: #0084ff;
  background-color: transparent;
}

.btn-outline-messenger:not(:disabled):not(.disabled):active,
.btn-outline-messenger:not(:disabled):not(.disabled).active,
.show > .btn-outline-messenger.dropdown-toggle {
  color: #fff;
  background-color: #0084ff;
}

.btn-outline-messenger:not(:disabled):not(.disabled):active:focus,
.btn-outline-messenger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-messenger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

/* override bootstrap css properties */
.modal-backdrop {
  opacity: 1 !important;
  background-color: #172b4d73 !important;
}

.modal-dialog {
  box-shadow: none !important;
}

.modal-header,
.modal-footer {
  background: transparent !important;
  border: none !important;
}

.btn-danger {
  color: #fff;
  background-color: #e91e63;
  border-color: #e91e63;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #10215b;
}

.capitalize {
  text-transform: capitalize;
}

.actions-card {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
}

iframe {
  border: none !important;
}

.ant-card-head-title p {
  margin-bottom: 0 !important;
}

.ant-card-body {
  font-size: 12px !important;
  padding: 0;
}

.ant-card {
  width: 100%;
  border-radius: 10px;
}

.ant-card-head-title,
.ant-statistic-title,
.indicator-detail {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.indicator-detail h4 {
  font-weight: 800 !important;
}

.ant-card-head {
  border-bottom: none !important;
  padding: 0 8px !important;
  min-height: auto;
}

.empty-card {
  min-height: 400px;
  justify-content: center;
  align-items: center;
}

.table {
  width: 100%;
  margin: 0;
  // overflow: hidden;
}

tbody {
  flex-grow: 1;
  overflow: auto;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: none;
  border-top: none;
  color: #000;
  font-weight: 600 !important;
  background: #fff !important;
  z-index: 12;
}

.ant-tag {
  font-weight: 600 !important;
}

.table td {
  opacity: 1 !important;
  font-size: 14px;
  max-width: 100%;
  overflow: hidden;
}

th {
  font-weight: 500 !important;
}

.ant-empty {
  margin: 50px auto;
}

.ant-empty-description {
  font-size: 14px;
  margin-top: 20px;
  color: #aeaeae;
}

.ant-menu-submenu-selected,
.ant-menu-submenu-active {
  color: white !important;
}

.nextui-button-text {
  z-index: 1 !important;
}

.action .success {
  margin: 5px;
}

button {
  outline: none !important;
}

.action .success,
.action .succes:hover {
  background-color: #dffff9 !important;
  border-color: #dffff9 !important;
  color: #3ae3bf !important;
}

.action .danger,
.action .danger:hover {
  background-color: #fff0f5 !important;
  border-color: #fff0f5 !important;
  color: #fe4676 !important;
}

.ant-progress-text {
  color: black;
  text-align: right;
  font-weight: 700;
}

.table-hover tbody tr:hover {
  background-color: #f5f5f594;
}

.table-hover tbody tr:focus {
  background-color: red;
}

.btn-light {
  background-color: white;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-input:focus {
  background-color: #0070f3;
  border-color: #0070f3;
}

.profile-header-wrapper {
  display: flex;
}

.not-selected,
.selected {
  margin: 5px;
  background: #f3f3f4;
  border: none;
  box-shadow: none;
}

.not-selected {
  background-color: #f3f3f4;
}

.selected,
.btn-actions-pane-right .selected:hover {
  background: #3ac47d;
  color: white;
}

.not-selected,
.btn-actions-pane-right .not-selected:hover {
  color: black;
  background-color: #f3f3f4;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  padding: 4px 0;
}

.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em;
  font-size: 11px;
  font-weight: 700;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #00000008;
}

.last-message-preview.active {
  color: initial;
}

.dropdown-item.active,
.dropdown-item:active {
  color: initial;
}

.nav-item {
  border-bottom: 1px solid #f1f1f1;
}

.menu-item-tooltip {
  left: 5.6rem !important;
  animation-duration: 0s !important;
}

.ant-tooltip-inner {
  text-align: left !important;
  font-weight: bold;
  padding: 0.7em 1em !important;
  background-color: #080e27;
  text-align: center;
  animation-duration: 0s !important;
  height: auto;
  border-radius: 12px;
  font-size: 14px;
  min-height: auto;
}

// .ant-tooltip {
//   z-index: 100;
// }

.required:before {
  content: '* ';
  color: red;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

.ant-btn-circle.ant-btn-lg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination {
  display: table !important;
}

.ant-btn.basic {
  font-family: 'Open Sans', sans-serif !important;
  color: white !important;
  cursor: pointer;
  font-weight: 600 !important;
  opacity: 1;
  text-decoration: none;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  -webkit-box-pack: center;
  flex-shrink: 0;
  margin: 0px;
  height: 45px !important;
  border: none !important;
  border-radius: 6px !important;
  position: relative;
  outline: 0px;
  box-shadow: rgb(0 0 0 / 5%) 0px 1px 2px 0px;
  transition: opacity 200ms linear 0s, background-color 200ms linear 0s,
    box-shadow 200ms linear 0s;
  display: flex !important;
  justify-content: space-evenly !important;
}

.form-group label {
  font-size: 14px;
  font-weight: 600;
}

.reactour-mask {
  color: #172b4d73 !important;
}

.basic-btn,
.basic-btn-grey,
.basic-btn-small,
.basic-btn-grey-small,
.ok-btn,
.blue-btn,
.grey-btn,
.red-btn,
.green-btn,
.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  height: auto;
  font-family: 'Exo 2', sans-serif;
  cursor: pointer;
  font-size: 16px;
  color: white;
  text-decoration: none;
  -webkit-box-align: center;
  background-color: #0070f3;
  align-items: center;
  flex-direction: row;
  -webkit-box-pack: center;
  flex-shrink: 0;
  margin: 0;
  border: none !important;
  border-radius: 10px !important;
  outline: 0;
  z-index: 1;
  font-weight: 600 !important;
  padding: 0.7em 1.1em;
  line-height: 1.75em;
}

.basic-btn-small {
  height: auto;
}

.basic-btn:hover,
.basic-btn:focus,
.basic-btn-small:focus,
.basic-btn-small:hover,
.ok-btn:hover,
.ok-btn:focus,
.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: var(--primary);
  border-color: var(--primary);
  transition: 0.15s ease all;
  color: white;
}

.basic-btn:hover,
.basic-btn:focus,
.ok-btn:hover {
  // background-color: #5875ec;
}

.basic-btn[disabled],
.basic-btn-grey[disabled] {
  color: white !important;
  background-color: #9aa6f8 !important;
}

.basic-btn-grey,
.basic-btn-grey-small {
  background-color: #f1f1f1 !important;
  color: #323232f2 !important;
}

.ant-input,
.ant-picker,
.ant-select {
  font-size: 16px;
  line-height: 25px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  font-size: 16px;
  font-weight: 600 !important;
  border-radius: 12px !important;
  background-color: #e6e6e67a;
}

label {
  font-size: 15px !important;
  font-weight: 500;
  //margin-top: 20px;
}

.grey-btn {
  background-color: #f3f3f4;
  border: none;
  color: #444;
  transition: 0.2s ease all;
  border-radius: 6px;
}

.blue-btn {
  font-size: 16px;
  background-color: #d6e3fd !important;
  color: var(--primary);
}

.blue-btn:hover {
  background-color: #bfd3f9 !important;
  font-weight: 700;
  color: var(--primary);
}

.grey-btn:hover {
  background-color: #e3e6ea !important;
  color: #444;
}

.blue-btn:disabled {
  color: #0070f363;
  background-color: #d6e3fd73;
  cursor: not-allowed;
}

.green-btn {
  background-color: #41cc67;
}

.green-btn:hover {
  background-color: #41cc67;
  color: white;
}

.red-btn {
  background-color: #d95762;
}

.red-btn:hover,
.red-btn:focus {
  background-color: #d95762;
  color: white;
}

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px;
  /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px;
  /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

// TABS
.ant-tabs-nav .ant-tabs-tab {
}

.ant-tabs-nav .ant-tabs-tab-active {
  font-weight: 700;
}

.ant-tabs-tab.ant-tabs-tab-active {
  opacity: 1;
}

.ant-tabs-tab-btn {
  font-size: 16px !important;
  font-weight: 500;
  border-radius: 0 !important;
  outline: 0;
  z-index: 11;
  letter-spacing: 0.2px;
  line-height: 1.75em;
  height: 30px;
}

.ant-tabs-ink-bar {
  position: absolute;
  pointer-events: none;
  height: 1px !important;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
}

.ant-tabs-tab {
  padding: unset;
}

.ant-tabs-ink-bar {
  height: 1px !important;
}

#root
  > div
  > div.app-main
  > div.app-main__inner
  > div.ant-tabs.ant-tabs-left
  > div.ant-tabs-nav
  > div.ant-tabs-nav-wrap
  > div
  > div.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  height: 38px !important;
  width: 1px !important;
}

.workflows-timeline-wrapper .vertical-timeline-element-content {
  position: relative;
  margin-left: 60px;
  background: #fff;
  padding: 1em;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%) !important;
  transition: 0.15s ease all;
  font-size: 13px !important;
  cursor: pointer;
}

.workflows-timeline-wrapper .vertical-timeline-element-content p {
  font-size: 13px !important;
}

.workflows-timeline-wrapper .vertical-timeline:before {
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 3px;
  background: #f3f3f4 !important;
}

.workflows-timeline-wrapper
  .vertical-timeline-element-content
  .vertical-timeline-element-date {
  color: black;
  font-weight: 600 !important;
}

.ant-mentions > textarea {
  font-size: 16px;
  min-height: 150px;
  width: 100%;
  border: none;
  outline: none;
  resize: vertical !important;
}

// Keyframe animation
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    top: 100px;
  }

  75% {
    opacity: 0.5;
    top: 0px;
  }

  100% {
    opacity: 1;
  }
}

$grey: rgba(0, 0, 0, 0.5);
$blue: rgba(0, 0, 255, 0.5);

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
    border-top-color: $grey;
  }

  50% {
    transform: rotate(180deg);
    border-top-color: $blue;
  }

  100% {
    transform: rotate(360deg);
    border-top-color: $grey;
  }
}

* {
  box-sizing: border-box;
}

@mixin loaderDivMixin {
  border-radius: 50%;
  padding: 8px;
  border: 2px solid transparent;
  animation: rotate linear 2s infinite;
}

.loader-candidates {
  position: relative;
  margin: auto;
  width: 150px;
  height: 150px;
  display: block;
  overflow: hidden;

  div {
    height: 100%;
  }
}

.loader-candidates,
.loader-candidates div {
  @include loaderDivMixin;
  border-top-color: #f9c32e;
  border-bottom-color: var(--primary);
}

.loader-candidates div:hover {
  animation-play-state: paused;
}

.loader-candidates,
.loader-candidates * {
  will-change: transform;
}

.candidate-search-input::placeholder {
  color: #00000061;
}

.card-footer:last-child {
  border: none !important;
}

.ant-progress-steps-item {
  background-color: var(--primary) !important;
}

.indicator-card h4 {
  font-size: 48px;
  font-weight: 800;
}

.link-element {
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.ant-modal-body {
  font-size: 16px;
}

.ant-modal-content {
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 5%) 0px 3px 20px;
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  border-radius: 20px 0px 0px 20px;
  //box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-size: 15px;
  font-weight: 600;
}

.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: #000000d9;
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
}

.ant-modal-content {
  padding: 1rem;
}

.ant-dropdown-menu {
  border-radius: 12px;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  outline: none;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}
.ant-modal-header,
.ant-drawer-header {
  padding: 24px 30px;
  border-radius: 12px;
}

.ant-modal-title {
  font-weight: 600;
  font-size: 25px;
  text-align: center;
}

.ant-drawer-body {
  flex-grow: 1;
  padding: 15px !important;
  overflow: auto;
  font-size: 15px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.back-step-btn,
.next-step-btn,
.grey-btn {
  color: white !important;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  -webkit-box-align: center;
  background-color: var(--primary);
  align-items: center;
  flex-direction: row;
  -webkit-box-pack: center;
  flex-shrink: 0;
  margin: 0;
  border: none !important;
  border-radius: 10px !important;
  outline: 0;
  z-index: 11;
  font-weight: 700 !important;
  padding: 0.7em 1.1em;
  line-height: 1.75em;
  height: auto;
}

.back-step-btn {
  border: none;
  position: fixed;
  bottom: 20px;
  left: 100px;
}

.next-step-btn {
  position: fixed;
  right: 90px;
  bottom: 20px;
}

.grey-btn {
  font-weight: 700 !important;
  background-color: #f3f3f4 !important;
  color: #1c1e21 !important;
}

.ant-drawer-title {
  font-size: 22px;
  font-weight: bold;
}

.app-main .app-main__inner {
  height: calc(100vh - 70px);
  margin-left: 100px;
  margin-right: 20px;
  padding: 70px 0 0;
}

.ant-card {
  background-color: transparent;
}

.ant-menu-dark {
  background: linear-gradient(135deg, #0d205f 0%, #010205 100%) !important;
  z-index: 1;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #0070f3;
  width: 58px;
  height: 58px;
  border-radius: 12px;
  margin: auto;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item {
  width: 58px;
  height: 58px;
  border-radius: 12px;
  margin: 10px auto;
  transition: 0.2s ease all;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item:hover:not(.ant-menu-item-selected) {
  background-color: #0070f34a;
}

.ant-menu-sub {
  padding: 0;
}

.ant-menu-sub .ant-menu-item-selected,
.ant-menu-sub .ant-menu-item {
  width: 100% !important;
  font-size: 16px;
  font-weight: 700;
  border-radius: 0 !important;
}

.ant-checkbox {
  display: flex;
  justify-content: center;
  align-content: center;
}

.ant-checkbox + span {
  font-size: 12px;
  text-decoration: none !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
}

.candidate-tag-select {
  margin-bottom: 40px;
}

.candidate-tag-select div div div:hover {
  color: black;
}

.react-select-container {
  width: 100%;
}

.react-select__control.react-select__control {
}

.react-select__menu,
.react-select__value-container {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75em;
  text-transform: capitalize !important;
}

.react-select__menu {
  z-index: 122222 !important;
}

.ant-avatar-string {
  font-weight: bold;
}

.ant-modal-footer,
.ant-modal-header,
.ant-drawer-header {
  border: none !important;
}

.ant-form-item-explain.ant-form-item-explain-error {
  font-size: 16px;
  font-weight: 600;
  color: #d95762 !important;
}

.ant-steps-item-error
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-size: 16px;
  font-weight: 600;
  color: #d95762 !important;
}

._loading_overlay_overlay {
  background: #ffffff9c !important;
}

.ant-menu-title-content {
  line-height: 1 !important;
}

.react-autosuggest__container {
  display: grid;
  place-items: center;
}

.react-autosuggest__input {
  transition: 0.2s ease all;
  background-color: #f3f3f4;
  border: none;
  width: 250px;
  height: 36px;
  padding: 10px;
  border-radius: 10px;
  font-size: 1em;
  font-family: 'Exo 2', sans-serif;
  font-weight: 300;
}

.react-autosuggest__input:hover,
.react-autosuggest__input:focus,
.react-autosuggest__input:active {
  background-color: #fff;
  border-color: var(--primary);
  -webkit-box-shadow: 0 0 0 4px rgb(45 80 230 / 10%);
  box-shadow: 0 0 0 4px rgb(45 80 230 / 10%);
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 999;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.ant-rate {
  font-size: 14px;
}

.ant-page-header-heading {
  margin-bottom: 11px;
}

.ant-page-header-heading-title {
  white-space: unset !important;
}

.notes-wrapper .comment-list {
  max-height: 100% !important;
}

.ant-form-item-label > label {
  color: #000000d9;
  font-size: 14px !important;
  font-weight: 600;
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  border-radius: 0;
  border-left: 1px solid #05081914 !important;
  box-shadow: none;
}

.table {
  overflow-x: hidden !important;
  /* Hide horizontal scrollbar */
}

.table th,
.table td {
  border-top: 1px solid #e9ecef80;
}

.table-hover tbody tr {
  border: 1px solid transparent;
  transition: 0.1s ease all;
}

.table-hover tbody tr:hover,
.clickable-row.seen:hover {
  background-color: #f5f5f594 !important;
}

.forms-wizard-alt .forms-wizard li {
  font-size: 14px;
  font-weight: 800;
}

.search-talent .candidate-card-wrapper {
  border: 1px solid transparent;
}

.forms-wizard li.form-wizard-step-doing {
  color: #000;
  // text-decoration: underline;
}

.search-talent .candidate-card-wrapper:hover {
  border: 1px solid #2d51e5;
  background-color: #2d51e514 !important;
  color: #2d51e5;
}

$total-items: 20;

.candidate-card-row {
  animation: none !important;
}

$grey: rgba(0, 0, 0, 0.5);
$blue: rgba(0, 0, 255, 0.5);

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
    border-top-color: $grey;
  }

  50% {
    transform: rotate(180deg);
    border-top-color: $blue;
  }

  100% {
    transform: rotate(360deg);
    border-top-color: $grey;
  }
}

@mixin loaderDivMixin {
  border-radius: 50%;
  padding: 8px;
  border: 2px solid transparent;
  animation: rotate linear 2s infinite;
}

.loader-candidates {
  position: relative;
  margin: auto;
  width: 150px;
  height: 150px;
  display: block;
  overflow: hidden;

  div {
    height: 100%;
  }
}

.loader-candidates,
.loader-candidates div {
  @include loaderDivMixin;
  border-top-color: var(--primary);
  border-bottom-color: var(--primary);
}

.loader-candidates div:hover {
  animation-play-state: paused;
}

.loader-candidates,
.loader-candidates * {
  will-change: transform;
}

.candidates-search-input {
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  width: 100%;
  height: 46px;
}

.table-action-elem {
  margin: 3px;
}

.candidate-row {
  height: 56px;
}

.candidate-card-row {
  padding: 0px;
}

.candidate-card-row:hover {
  cursor: pointer;
}

.candidate-card-row-body {
  display: flex;
}

.candidate-card-informations {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 1.75;
}

.candidate-card-name {
  font-size: 20px;
  font-weight: 800;
}

.candidate-conversation-wrapper {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.conversation-score {
  padding: 0 5px;
}

.conversation-informations {
  display: flex;
  flex-direction: column;
  padding: 0 5px;
}

.conversation-actions {
  margin-left: auto;
  order: 2;
}

.candidate-attachment {
  display: inline-block;
  margin: 0px;
}

.candidate-attachment > a {
  padding: 2px;
  background: var(--secondary);
}

.candidate-attachment-name {
  visibility: hidden;
  position: absolute;
  z-index: 1000;
  background: #d3d3d370;
  height: 100%;
  width: 100%;
  left: -0px;
  color: var(--primary);
  font-weight: bold;
  top: -0px;
  padding-top: 30px;
}

.candidate-attachment:hover .candidate-attachment-name {
  visibility: visible;
}

.without-preview .candidate-attachment-name {
  visibility: visible;
}

.candidate-attachment .placeholder {
  display: inline-block;
  height: 121px;
  width: 89px;
}

.candidate-attachment-btn {
  padding: 2px;
}

.without-preview .candidate-attachment-btn {
  background: transparent;
}

.file-modal {
  display: inline-block;
  max-width: fit-content;
}

.file-modal-wrapper {
  text-align: center;
}

.candidate-card-actions-reengagement {
  display: flex;
  flex-direction: row;
}

.candidate-card-action-row {
  width: 100%;
}

.btn-reengagement {
  width: 100%;
}

.candidates-search-text-div {
  text-align: left;
  font-size: 14px;
}

.search-selection-card {
  height: 250px;
  width: 250px;
}

.search-selection-card-available {
  cursor: pointer;
  transition: transform 0.2s;
  border-width: 2px;
}

.search-selection-card-available:hover {
  transform: scale(1.02);
  border-width: 2px;
  border-style: solid;
  border-color: var(--primary);
}

.ant-card-cover img {
  border-radius: 0 0 10px 10px;
}

.candidate-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.send-talent {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.table-action-elem {
  margin: 3px;
}

.table-action-elem-dropdown-btn {
  padding: 0;
}

.basic-tag {
  font-size: 13px;
  font-weight: 800;
  border-radius: 8px;
  padding: 0.3em 0.8em;
  align-self: top;
  transition: none !important;
}

.div-filters {
  display: flex;
}

.div-only-user-filter {
  display: flex;
}

.div-teams-filter {
  min-width: 300px;
}

.div-users-filter {
  min-width: 250px;
  margin-left: 5px;
}

.btn-group,
.btn-group-vertical {
  position: initial;
}

.iframe-loader {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: #172b4d73 !important;
  z-index: 1;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
}

.select-sources input {
  height: 100% !important;
}

.select-sources {
  width: 100%;
}

.select-sources button {
  display: none;
}

.rdw-suggestion-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid #f1f1f1;
  border-radius: 12px !important;
  min-width: 100px;
  max-height: 250px;
  /* overflow: auto; */
  background: #fff;
  z-index: 111111;
}

.rdw-suggestion-option {
  padding: 7px 5px;
  border-bottom: 1px solid #f1f1f1;
  width: 14rem;
}

.react-autosuggest__suggestion {
  border: 1px solid transparent;
  border-radius: 15px;
  transition: 0.2s ease all;
  padding: 0.5em;
}

.react-autosuggest__suggestion:hover {
  border: 1px solid #2d51e5;
  background-color: #2d51e514 !important;
  color: #2d51e5;
}

.react-autosuggest__section-title {
  margin: 15px 0;
  /* color: var(--primary);*/
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.3px;
  font-weight: 800 !important;
}

.table-card-header {
  font-size: 24px;
  text-align: center;
  margin-bottom: 70px;
}

// .ant-card-bordered {
//   border-radius: 28px;
//   border: 1px solid #05081914 !important;
// }

.search-talent .candidate-card-wrapper:hover {
  border: 1px solid #2d51e5 !important;
  background-color: #2d51e514 !important;
  color: #2d51e5;
}

.search-talent .candidate-card-wrapper {
  border: 1px solid #0000;
  margin-bottom: 10px;
  transition: 0.2s ease all;
}

.ant-tabs-nav .ant-tabs-nav-wrap {
  margin-bottom: 10px;
}

.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 0;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 5px !important;
  padding-right: 25px !important;
}

.ant-tabs-left
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane,
.ant-tabs-left
  > div
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane {
  padding-left: 30px;
}

#root
  > div
  > div.app-main
  > div.app-main__inner
  > div.ant-tabs.ant-tabs-left
  > div.ant-tabs-nav
  > div.ant-tabs-nav-wrap
  > div {
  margin-top: 32px;
}

.chatbot-settings-tabs {
  margin-top: 10vh;
}

.ant-checkbox-wrapper {
  text-transform: capitalize;
  font-size: 12px !important;
}

.focused {
  background-color: #f5f5f594;
}

.table-responsive {
  overflow-x: hidden;
}

.ant-steps-item-title {
  font-weight: 600 !important;
}

#jimo_notifications {
  display: none;
}

.dealbreaker-option .disabled {
  color: lightgrey;
  background-color: white;
  border: lightgrey solid 1px;
}

.buttons-options-group {
  overflow-x: auto;
  flex-wrap: nowrap;
  padding-right: 0;
  padding-left: 0;
}

.buttons-option-card {
  width: 100%;
  margin: 5px;
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reactivation-tabs .ant-tabs-tab-active,
.reactivation-tabs .ant-tabs-tab {
  text-align: center;
  flex: 1;
  display: grid;
  width: 100%;
}

// Tabs management
.ant-tabs-tab {
  transition: none;

  &-btn {
    //color: #5066F0;
    transition: none;
    font-size: 14px !important;
    font-weight: 600;
    opacity: 0.45;
  }

  & .ant-tabs-ink-bar {
    height: 1px !important;
    //background-color: #5066F0;
  }
}

.ant-tabs-ink-bar {
  height: 2px !important;
  background-color: #0070f3;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0070f3 !important;
  opacity: 1;
}

.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 13px !important;
  font-weight: 700;
}

.ant-tabs-nav {
  margin: 0 !important;
}

.ant-tabs-tab:hover,
.ant-tabs-tab:focus {
  color: #0070f3;
}

.ant-tabs-tab.ant-tabs-tab {
  display: block;
}

.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
  //  font-size: 16px !important;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  // display: flow-root;
}

.ant-descriptions-item-label {
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5715;
  text-align: start;
  font-size: 14px;
  color: #0070f3 !important;
  text-decoration: underline;
  margin-top: 29px;
  /* text-transform: uppercase; */
}

.ant-descriptions-item-content {
  font-size: 12px;
  line-height: 1.75;
}

.dashboard-tabs .ant-tabs-nav-list {
  margin-left: 12px;
}

.ant-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-size: 16px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  word-wrap: break-word;
  border-radius: 12px;
}

.ant-page-header-heading-extra > * {
  white-space: initial;
}

.talents-count {
  height: auto;
  font-size: 22px;
  font-weight: 800;

  span {
    font-weight: 300;
    margin-left: 5px;
    font-size: 18px;
    opacity: 0.5;
    margin-top: 3px;
  }
}

.btns-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.select-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  padding: 0.5em;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  &__list {
    max-height: 400px;
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding: 0.2em 0;
    overflow-y: scroll;
    background-color: white;
  }
}

.btn-clear {
  font-weight: 600;
  color: #2d50e5;
  opacity: 1;
  cursor: pointer;
  border: none;
  background-color: white !important;
  background: white !important;
}

.select-location {
  width: 175px !important;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  z-index: 999;
}

.react-autosuggest__suggestions-container {
  z-index: 999 !important;
}

.table thead th {
  z-index: 1;
}

.ant-drawer-close {
  position: absolute !important;
  top: 10px;
  right: 0;
  z-index: 10;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
  padding: 0 !important;
}

.ant-modal-header,
.ant-drawer-header {
  padding: 0;
}

.input-create {
  width: 284px !important;
  height: 45px;
  border-radius: 8px;
}

.ant-switch-checked {
  background-color: var(--primary) !important;
}

/* .ant-result {
    padding: 0px 32px;
    padding-bottom: 240px;
} */

.campaign-input-title {
  height: 100%;
  display: flex;
  align-items: center;
}

.form-item-campaign {
  padding-top: 16px;
}
